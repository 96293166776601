<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header
                    :title="$t('messages')"
                    :isFilter="false">
				</Header>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile
                    :title="$t('messages')"
                    :isFilter="false">
				</HeaderMobile>
			</template>
            <b-tabs content-class="py-2 position-relative" v-model="tabIndex">
                <div class="tabs-bottom-line"></div>
                <b-tab active>
                    <template #title><i class="ri-inbox-line top-plus-2 mr-2"></i>{{ $t('inbox') }}</template>

                </b-tab>
                <b-tab>
                    <template #title><i class="ri-send-plane-2-line top-plus-2 mr-2"></i>{{ $t('outbox') }}</template>

                </b-tab>
                <b-tab @click="newMessageShow" v-if="checkPermission('message_store')">
                    <template #title><i class="ri-mail-line top-plus-2 mr-2"></i>{{ $t('message_new') }}</template>

                </b-tab>
            </b-tabs>
            <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-3">
                    <message-navi
                        :currentBox="tabIndex"
                        @getDetailId="showMessageDetail"></message-navi>
                </div>
                <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-9">
                    <message-detail
                        v-show="msgId"
                        :msgId="msgId"
                        @goBack="goToTable"
                    ></message-detail>
                    <message-table
                        v-show="!msgId"
                        :currentBox="tabIndex"
                        @getDetailId="showMessageDetail"
                    ></message-table>
                </div>
            </div>

            <CommonModal ref="newMessageModal" size="xxl">
                <template v-slot:CommonModalTitle><i class="ri-mail-add-line top-plus-2 mr-2"></i> {{ $t('new_message') }}</template>
                <template v-slot:CommonModalContent>
                    <new-message-form
                        v-if="newMessageIf"
                        @closeBox="newMessageClose"></new-message-form>
                </template>
            </CommonModal>
		</app-layout>
	</div>
</template>
<script>
	// Template
	import AppLayout from "@/layouts/AppLayout"
	import Header from "@/layouts/AppLayout/Header"
	import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
    //Pages
    import MessageNavi from "./MessageNavi";
    import NewMessageForm from "./NewMessageForm";
    import MessageDetail from "./MessageDetail";
    import MessageTable from "./MessageTable";
    //Components
    import CommonModal from "@/components/elements/CommonModal";



	export default {
		components: {
			AppLayout,
			Header,
			HeaderMobile,

            MessageNavi,
            NewMessageForm,
            MessageDetail,
            MessageTable,
            CommonModal

		},
		metaInfo() {
			return {
				title: this.$t('messages')
			}
		},
		data() {
			return {
                tabIndex: 0,
                newMessageIf: false,
                msgId: null
			}
		},
        watch: {
            tabIndex: {
                handler(value){
                    this.msgId = null
                    if(value == 2){
                        this.tabIndex = 0
                    }
                }
            }
        },
		created() {

		},
		methods: {
            newMessageShow() {
                this.newMessageIf = true
                this.$refs.newMessageModal.$refs.commonModal.show()
            },

            newMessageClose() {
                this.newMessageIf = false
                this.tabIndex = 1
                this.$refs.newMessageModal.$refs.commonModal.hide()
            },

            showMessageDetail(id){
                this.msgId = typeof id == 'object' ? id.value : id
            },

            goToTable(){
                this.msgId = null
            }
		}
	}
</script>

